import React from "react"
import LayoutA4 from "../../components/layout-a4"
import EnrollStep from "../../components/enroll-step"
import enrollStyles from "./a4_enroll.module.css"

export default class A4EnrollStep extends React.Component {
  render() {
    const color='#7e5695'
    const src='/demos/themeA4/enroll'
    return(
      <LayoutA4 >
        <div className={enrollStyles.enrollContainer}>
          <EnrollStep color={color} src={src}/>
        </div>
      </LayoutA4>
    )
  }
}